// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__root___sqURO{align-items:center;display:flex;justify-content:center;text-align:center;width:100%}.styles__root___sqURO h3{font-size:4em;margin:0}.styles__root___sqURO h4{font-size:1.75em;margin:8px 0 16px}`, "",{"version":3,"sources":["webpack://./src/routes/app/components/NotFound/styles.scss"],"names":[],"mappings":"AAAA,sBAGI,kBAAA,CADA,YAAA,CAEA,sBAAA,CACA,iBAAA,CAJA,UAIA,CAEA,yBACI,aAAA,CACA,QAAA,CAGJ,yBACI,gBAAA,CACA,iBAAA","sourcesContent":[".root {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n\n    h3 {\n        font-size: 4em;\n        margin: 0;\n    }\n\n    h4 {\n        font-size: 1.75em;\n        margin: 8px 0 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles__root___sqURO`
};
export default ___CSS_LOADER_EXPORT___;
