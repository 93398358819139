// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/styles/offlineFonts/Lato/Lato-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/styles/offlineFonts/Lato/Lato-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/styles/offlineFonts/Lato/Lato-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/src/styles/offlineFonts/Lato/Lato-Black.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:Lato;font-style:normal;font-weight:300;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2")}@font-face{font-family:Lato;font-style:normal;font-weight:400;src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2")}@font-face{font-family:Lato;font-style:normal;font-weight:700;src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2")}@font-face{font-family:Lato;font-style:normal;font-weight:900;src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2")}`, "",{"version":3,"sources":["webpack://./src/styles/offlineFonts/Lato/Lato.scss"],"names":[],"mappings":"AAAA,WACE,gBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA,CAEF,WACE,gBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA,CAIF,WACE,gBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA,CAEF,WACE,gBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA","sourcesContent":["@font-face {\n  font-family: \"Lato\";\n  src: url(\"/src/styles/offlineFonts/Lato/Lato-Light.woff2\") format(\"woff2\");\n  font-weight: 300;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(\"/src/styles/offlineFonts/Lato/Lato-Regular.woff2\") format(\"woff2\");\n  font-weight: 400;\n  font-style: normal;\n}\n\n\n@font-face {\n  font-family: \"Lato\";\n  src: url(\"/src/styles/offlineFonts/Lato/Lato-Bold.woff2\") format(\"woff2\");\n  font-weight: 700;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(\"/src/styles/offlineFonts/Lato/Lato-Black.woff2\") format(\"woff2\");\n  font-weight: 900;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
