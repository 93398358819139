// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__emojiContainer___dZYpw{align-content:center;align-items:center;color:#000;display:flex;font-size:1.6em;font-weight:100;justify-content:center;padding:.5em 1em}`, "",{"version":3,"sources":["webpack://./src/components/Emoji/styles.scss"],"names":[],"mappings":"AAAA,gCAGE,oBAAA,CADA,kBAAA,CAKA,UAAA,CANA,YAAA,CAKA,eAAA,CAEA,eAAA,CAJA,sBAAA,CACA,gBAGA","sourcesContent":[".emojiContainer{\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  padding: 0.5em 1em ;\n  font-size: 1.6em;\n  color: #000000;\n  font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emojiContainer": `styles__emojiContainer___dZYpw`
};
export default ___CSS_LOADER_EXPORT___;
