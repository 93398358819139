// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__bottomButtonContainer___Q3hJw{display:flex;flex-flow:column nowrap;height:100%;overflow:hidden;width:100%}.styles__innerWrapper___gRCc3{flex-grow:1}.styles__hideBottomBtn___HbNtL{height:0}`, "",{"version":3,"sources":["webpack://./src/components/BottomButtonContainer/styles.scss"],"names":[],"mappings":"AACA,uCAGI,YAAA,CACA,uBAAA,CAFA,WAAA,CAGA,eAAA,CAJA,UAIA,CAGJ,8BACI,WAAA,CAGJ,+BAGI,QAAA","sourcesContent":["\n.bottomButtonContainer {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-flow: column nowrap;\n    overflow: hidden;\n}\n\n.innerWrapper {\n    flex-grow: 1;\n}\n\n.hideBottomBtn {\n    // This is used for a CSS animation, the bottom button\n    // has a CSS transition set on 'margin'.\n    height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomButtonContainer": `styles__bottomButtonContainer___Q3hJw`,
	"innerWrapper": `styles__innerWrapper___gRCc3`,
	"hideBottomBtn": `styles__hideBottomBtn___HbNtL`
};
export default ___CSS_LOADER_EXPORT___;
