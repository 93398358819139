// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/styles/offlineFonts/Mulish/Mulish-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/styles/offlineFonts/Mulish/Mulish-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/styles/offlineFonts/Mulish/Mulish-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:Mulish;font-style:normal;font-weight:800;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2")}@font-face{font-family:Mulish;font-style:normal;font-weight:700;src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2")}@font-face{font-family:Mulish;font-style:normal;font-weight:400;src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2")}`, "",{"version":3,"sources":["webpack://./src/styles/offlineFonts/Mulish/Mulish.scss"],"names":[],"mappings":"AAAA,WACE,kBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA,CAEF,WACE,kBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA,CAEF,WACE,kBAAA,CAGA,iBAAA,CADA,eAAA,CADA,2DAEA","sourcesContent":["@font-face {\n  font-family: \"Mulish\";\n  src: url(\"/src/styles/offlineFonts/Mulish/Mulish-ExtraBold.woff2\") format(\"woff2\");\n  font-weight: 800;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Mulish\";\n  src: url(\"/src/styles/offlineFonts/Mulish/Mulish-Bold.woff2\") format(\"woff2\");\n  font-weight: 700;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Mulish\";\n  src: url(\"/src/styles/offlineFonts/Mulish/Mulish-Regular.woff2\") format(\"woff2\");\n  font-weight: 400;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
