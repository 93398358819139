// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___o8Xws{width:100%}.styles__topRow___b54gx{align-items:center;display:flex;justify-content:flex-start;margin-top:10px}.styles__innerWrapper___RP9dW{display:flex;flex-flow:column nowrap;padding:1.5em;width:100%}.styles__innerWrapper___RP9dW>div{width:100%!important}.styles__innerWrapper___RP9dW h2{margin:0}.styles__fullWidth___fgCuf{width:100%}a.styles__itemLink___C6XN_{color:blue;text-decoration:none}a.styles__itemLink___C6XN_:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/routes/app/surveys/components/SurveySolvePage/styles.scss"],"names":[],"mappings":"AAEA,yBACE,UAAA,CAGF,wBAEE,kBAAA,CADA,YAAA,CAEA,0BAAA,CACA,eAAA,CAGF,8BAGE,YAAA,CACA,uBAAA,CAFA,aAAA,CADA,UAGA,CAEA,kCACE,oBAAA,CAGF,iCACE,QAAA,CAIJ,2BACE,UAAA,CAGF,2BACE,UAAA,CACA,oBAAA,CAEA,iCACE,yBAAA","sourcesContent":["@import \"../../../../../styles/colors.json\";\n\n.wrapper {\n  width: 100%;\n}\n\n.topRow{\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 10px;\n}\n\n.innerWrapper {\n  width: 100%;\n  padding: 1.5em;\n  display: flex;\n  flex-flow: column nowrap;\n\n  & > div {\n    width: 100% !important;\n  }\n\n  h2 {\n    margin: 0;\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n\na.itemLink {\n  color: blue;\n  text-decoration: none;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___o8Xws`,
	"topRow": `styles__topRow___b54gx`,
	"innerWrapper": `styles__innerWrapper___RP9dW`,
	"fullWidth": `styles__fullWidth___fgCuf`,
	"itemLink": `styles__itemLink___C6XN_`
};
export default ___CSS_LOADER_EXPORT___;
