// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__paper___AwosM{background-color:#e0e0e0!important;border-radius:0!important;color:#fff!important;height:72px;width:100%;z-index:2}.styles__paper___AwosM,.styles__paper___AwosM .styles__banner___yC3NG{display:flex;justify-content:center}.styles__paper___AwosM .styles__banner___yC3NG{align-items:center;color:#585858}.styles__paper___AwosM .styles__innerWrapper___T4Tf2{max-width:600px;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/MessageBanner/styles.scss","webpack://./../../styles/colors.json"],"names":[],"mappings":"AAEA,uBAaE,kCAAA,CAEA,yBAAA,CADA,oBAAA,CANA,WAAA,CACA,UAAA,CAHA,SASA,CAEA,sEANA,YAAA,CACA,sBCJS,CDST,+CAEE,kBAAA,CAEA,aCbO,CDgBT,qDAEE,eAAA,CADA,UACA","sourcesContent":["@import \"../../styles/colors.json\";\n// Styles adapted from BottomButton/styles.scss\n.paper {\n\n  // z-index here is set to 2 so it can hide behind\n  // \":global(.contentWrapper).tabsWrapper\" (z-index: 3) when sliding down\n  // while also staying above the form fields in UserInputs\n  // found in src/layouts/AppLayout/styles.scss\n  z-index: 2;\n\n  height: 72px;\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  background-color: $lightGrey !important;\n  color: $lightText !important;\n  border-radius: 0 !important;\n\n  .banner {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: $darkGrey;\n  }\n\n  .innerWrapper {\n    width: 100%;\n    max-width: 600px;\n  }\n}\n","$darkBrandColor: #4611a9;\n$lightHighlight: #a4e4d3;\n$lightText: white;\n$darkYellow: #a97611;\n$emptyBackground: #f7f7f7;\n$darkText: black;\n$muiSecondary: #A10036;\n$disabledColor: rgba(0, 0, 0, 0.6);\n$lightGrey: rgb(224, 224, 224);\n$mediumGrey: rgb(188, 188, 188);\n$darkGrey: rgb(88, 88, 88);\n$muiPrimary: rgb(70, 17, 169);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `styles__paper___AwosM`,
	"banner": `styles__banner___yC3NG`,
	"innerWrapper": `styles__innerWrapper___T4Tf2`
};
export default ___CSS_LOADER_EXPORT___;
