// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__dialogTitle___GeC25{padding:8px 16px}.styles__dialogContent___rDXzQ{padding:5px 16px}`, "",{"version":3,"sources":["webpack://./src/components/InterestPassionPopper/styles.scss"],"names":[],"mappings":"AAAA,6BACG,gBAAA,CAEH,+BACE,gBAAA","sourcesContent":[".dialogTitle{\n   padding: 8px 16px;\n}\n.dialogContent{\n  padding: 5px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogTitle": `styles__dialogTitle___GeC25`,
	"dialogContent": `styles__dialogContent___rDXzQ`
};
export default ___CSS_LOADER_EXPORT___;
