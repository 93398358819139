// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__bottomButtonWrapper___eTOHW{transition:height .2s ease-out!important;width:100%}.styles__bottomButtonWrapper___eTOHW .styles__paper___PG2BS{background-color:#4611a9!important;color:#fff!important;display:flex;height:72px;justify-content:center;width:100%;z-index:2}.styles__bottomButtonWrapper___eTOHW .styles__paper___PG2BS .styles__button___QszY2{align-items:center;display:flex;text-align:center;width:300px}@media screen and (max-width:599px){.styles__bottomButtonWrapper___eTOHW .styles__paper___PG2BS .styles__button___QszY2{width:250px}}.styles__bottomButtonWrapper___eTOHW .styles__paper___PG2BS .styles__innerWrapper___sG7Iz{max-width:600px;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/BottomButton/styles.scss"],"names":[],"mappings":"AAEA,qCAGE,wCAAA,CADA,UACA,CAEA,4DAaE,kCAAA,CACA,oBAAA,CAHA,YAAA,CAHA,WAAA,CAIA,sBAAA,CAHA,UAAA,CAHA,SAQA,CAEA,oFAEE,kBAAA,CADA,YAAA,CAEA,iBAAA,CACA,WAAA,CAEA,oCANF,oFAOM,WAAA,CAAA,CAIN,0FAEE,eAAA,CADA,UACA","sourcesContent":["@import \"../../styles/colors.json\";\n\n.bottomButtonWrapper {\n\n  width: 100%;\n  transition: height 0.2s ease-out !important;\n\n  .paper {\n\n    // z-index here is set to 2 so it can hide behind\n    // \":global(.contentWrapper).tabsWrapper\" (z-index: 3) when sliding down\n    // while also staying above the form fields in UserInputs\n    // found in src/layouts/AppLayout/styles.scss\n    z-index: 2;\n\n    height: 72px;\n    width: 100%;\n\n    display: flex;\n    justify-content: center;\n    background-color: $darkBrandColor !important;\n    color: $lightText !important;\n\n    .button {\n      display: flex;\n      align-items: center;\n      text-align: center;\n      width: 300px;\n\n      @media screen and (max-width: 599px) {\n          width: 250px;\n      }\n    }\n\n    .innerWrapper {\n      width: 100%;\n      max-width: 600px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomButtonWrapper": `styles__bottomButtonWrapper___eTOHW`,
	"paper": `styles__paper___PG2BS`,
	"button": `styles__button___QszY2`,
	"innerWrapper": `styles__innerWrapper___sG7Iz`
};
export default ___CSS_LOADER_EXPORT___;
