const React = require('react');
const T = require('prop-types');
const StrangeForms = require('strange-forms');
const { default: TextField } = require('@mui/material/TextField');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');

const { default: Classes } = require('./styles.scss');
const EmojiPicker = require('../EmojiPicker');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: Select } = require('@mui/material/Select');
const { default: Input } = require('@mui/material/Input');
const { default: Chip } = require('@mui/material/Chip');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: styled } = require('styled-components');

const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(2)};
  margin-top: 10px;
  min-width: 120px;
  max-width: 550px;
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  margin: 2px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};


class EditGroupPage extends StrangeForms(React.Component) {

    static propTypes = {
        _class: T.shape({
            type: T.string,
            name: T.string,
            emojiSymbol: T.string,
            facilitator: T.string,
            shortDescription: T.string,
            additionalDescription: T.string,
            classLocation: T.string,
            number: T.string,
            private: T.bool,
            room: T.string,
            campus: T.string,
            section: T.string,
            semester: T.string,
            credits: T.number,
            misc: T.string,
            roles: T.array
        }),

        act: T.func.isRequired,
        onKeyDown: T.func.isRequired,

        // Beneficial for admins who manage several schools
        school: T.string,
        schoolRoles: T.array.isRequired,
        setGlobalInputPageError: T.func,
        removeGlobalInputPageError: T.func,
        globalInputPageHasError: T.bool,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool
    }

    constructor(props) {

        super(props);

        this.strangeForm({
            // Declare the interesting fields.
            fields: [
                'name',
                'emojiSymbol',
                'type',
                'facilitator',
                'private',
                'shortDescription',
                'additionalDescription',
                'classLocation',
                'number',
                'room',
                'campus',
                'section',
                'semester',
                'credits',
                'misc',
                'roles'
            ],
            // Obtain a form field value from props.
            // Can also be a single string, single function, or null (see strange-forms API docs).
            get: (someProps, field) => someProps._class[field] || '',
            // Also takes per-field format similar to get.
            act: props.act,
            // Defaults to (e) => e.target.value.  Also takes per-field format similar to get.
            getFormValue: {

                // handle checkbox
                private: (ev, isChecked) => isChecked,
                emojiSymbol: (emojiSymbol) => emojiSymbol,
                '*': (e) => e.target.value
            }
        });

        this.error = this._error.bind(this);
    }

    getFormValue(e) {

        return e.target.value || '';
    }

    static validate(field, value) {


        if (field === 'name') {
            if (!value) {
                return 'Name is required';
            }
            else if (value.length < 1){
                return 'Name is required';
            }

        }

        if (field === 'roles') {
            if (!value || value.length < 1) {
                return 'At least one role is required';
            }
        }

        return null;
    }

    static allValid(values) {

        return Object.keys(values).every((field) => {

            return EditGroupPage.validate(field, values[field]) === null;
        });
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? EditGroupPage.validate(field,this.fieldValue(field)) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            if (this.props.globalInputPageHasError){
                this.props.removeGlobalInputPageError();
            }

            return null;

        } // eslint-disable-next-line no-else-return
        else {
            if (!this.props.globalInputPageHasError){
                this.props.setGlobalInputPageError();
            }

        }

        return EditGroupPage.validate(field, this.fieldValue(field));
    }

    render() {

        const {
            schoolRoles,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        return <div>

            <div style={{ paddingTop: 20 }}>
                <p style={{ marginBottom: 0 }} id={'demo-mutiple-chip-label'}>Who should have permissions to join this group?</p>

                <StyledFormControl >
                    <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={this.fieldValue('roles')}
                        onChange={this.proposeNew('roles')}
                        input={<Input id="select-multiple-chip" />}
                        error={!!this.state.rolesErrorShow}
                        onBlur={this.showError('roles')}
                        onFocus={this.showError('roles', false)}
                        renderValue={(selected) => {

                            return (
                                <StyledChips>
                                    {selected.map((value) => {

                                        const schoolRole = schoolRoles && schoolRoles.find((role) => {

                                            return role.id === value;
                                        });

                                        // Defense against an err if a role doesn't actually belong to a school
                                        if (!schoolRole) {
                                            return null;
                                        }

                                        return <StyledChip key={value} label={`${schoolRole.label}`}/>;
                                    })}
                                </StyledChips>
                            );
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="" disabled>
                            <ListItemText primary={`Select Roles`} />
                        </MenuItem>

                        {schoolRoles.map((schoolRole) => {

                            return (
                                <MenuItem key={schoolRole.id} value={schoolRole.id}>
                                    <Checkbox checked={this.fieldValue('roles').indexOf(schoolRole.id) > -1} />
                                    <ListItemText primary={`${schoolRole.label}`} />
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {this.state.rolesErrorShow && <FormHelperText  error={!!this.state.rolesErrorShow}>{this.error('roles')}</FormHelperText>}
                </StyledFormControl>
            </div>
            <div style={{ maxWidth:'20em',padding:'1em 0em' }}>
                <EmojiPicker
                    value={this.fieldValue('emojiSymbol')}
                    onChange={this.proposeNew('emojiSymbol')}
                    label={`Selected ${this.fieldValue('type') === 'group' || !this.fieldValue('type') ? 'Group' : 'Class'} Emoji:`}
                />
            </div>

            <div onKeyDown={this.props.onKeyDown} className={Classes.gridFieldset}>

                <TextField
                    fullWidth
                    label={`*${this.fieldValue('type') === 'group' ? 'Group' : 'Class'} Name`}
                    value={this.fieldValue('name')}
                    onChange={this.proposeNew('name')}
                    error={this.state.nameErrorShow}
                    placeholder='Physics 101'
                    onBlur={this.showError('name')}
                    onFocus={this.showError('name', false)}
                    helperText={this.error('name')}
                    inputProps={{ maxLength: 50 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}

                />

                <TextField
                    fullWidth
                    label='Facilitator'
                    value={this.fieldValue('facilitator')}
                    onChange={this.proposeNew('facilitator')}
                    placeholder='Mr. Meeseeks'
                    inputProps={{ maxLength: 50 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}

                />

                {(!isTeamsApp && !isCompany && !isCommunity && !isSchoolOnline) ? <TextField
                    fullWidth
                    label='Campus'
                    value={this.fieldValue('campus')}
                    onChange={this.proposeNew('campus')}
                    placeholder='Watson School of Engineering'
                    inputProps={{ maxLength: 50 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                /> : null}

                <TextField
                    fullWidth
                    label='Short Description'
                    value={this.fieldValue('shortDescription')}
                    onChange={this.proposeNew('shortDescription')}
                    placeholder='FYS'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

                {this.fieldValue('type') === 'class' && <TextField
                    fullWidth
                    label='Number'
                    value={this.fieldValue('number')}
                    onChange={this.proposeNew('number')}
                    placeholder='EECE 252'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />}

                {(!isTeamsApp && !isCompany && !isCommunity) ? <TextField
                    fullWidth
                    label='Term or Semester'
                    value={this.fieldValue('semester')}
                    onChange={this.proposeNew('semester')}
                    placeholder='F2017'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                /> : null}

                <TextField
                    fullWidth
                    label='Additional Description'
                    value={this.fieldValue('additionalDescription')}
                    onChange={this.proposeNew('additionalDescription')}
                    placeholder='TR 10:30−11:45'
                    inputProps={{ maxLength: 100 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}

                />

                {this.fieldValue('type') === 'class' && <TextField
                    type='number'
                    fullWidth
                    label='Credits'
                    value={this.fieldValue('credits')}
                    onChange={this.proposeNew('credits')}
                    placeholder='4'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />}

                <TextField
                    fullWidth
                    label='Location'
                    value={this.fieldValue('classLocation')}
                    onChange={this.proposeNew('classLocation')}
                    placeholder='AS GOTWA201'
                    inputProps={{ maxLength: 50 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}

                />

                {this.fieldValue('type') === 'class' && <TextField
                    fullWidth
                    label='Room'
                    value={this.fieldValue('room')}
                    onChange={this.proposeNew('room')}
                    placeholder='SCI 201'
                    inputProps={{ maxLength: 10 }}
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}

                />}

                <TextField
                    fullWidth
                    label='Other Search Terms'
                    value={this.fieldValue('misc')}
                    onChange={this.proposeNew('misc')}
                    placeholder='DiffEQ'
                    classes={{
                        root:Classes.textFieldRoot
                    }}
                    InputProps={{
                        classes:{
                            root:Classes.textFieldInputRoot
                        }
                    }}
                />

            </div>

            <h3>Privacy Settings</h3>
            <p>A private group is not visible in search and members must be manually added</p>

            <FormControlLabel
                label='Set As Private'
                control={<Checkbox
                    checked={!!this.fieldValue('private')}
                    onChange={this.proposeNew('private')}
                />}
            />
        </div>;
    }
}

module.exports = EditGroupPage;
