// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__badge___d465t{position:absolute;right:6px;top:6px}`, "",{"version":3,"sources":["webpack://./src/routes/app/components/NotificationsButton/styles.scss"],"names":[],"mappings":"AAEA,uBACI,iBAAA,CAEA,SAAA,CADA,OACA","sourcesContent":["@import \"../../../../styles/colors.json\";\n\n.badge {\n    position: absolute;\n    top: 6px;\n    right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `styles__badge___d465t`
};
export default ___CSS_LOADER_EXPORT___;
