// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__offlineBanner___SG1Mz{background:#ff0;border-radius:0 0 10px 10px;font-size:14px;font-weight:700;height:0;left:calc(50% - 100px);line-height:30px;overflow:hidden;position:fixed;right:calc(50% - 100px);text-align:center;transition:height .5s cubic-bezier(.4,0,.2,1);width:auto!important;z-index:5;z-index:9999}.styles__offlineBanner___SG1Mz.styles__currentlyOffline___s35jV{height:30px!important}`, "",{"version":3,"sources":["webpack://./src/components/OfflineIndicator/styles.scss"],"names":[],"mappings":"AAAA,+BAKE,eAAA,CAEA,2BAAA,CADA,cAAA,CAGA,eAAA,CAEA,QAAA,CATA,sBAAA,CAUA,gBAAA,CACA,eAAA,CAZA,cAAA,CAEA,uBAAA,CAKA,iBAAA,CAMA,6CAAA,CAJA,oBAAA,CANA,SAAA,CAYA,YAAA,CAEA,gEACE,qBAAA","sourcesContent":[".offlineBanner {\n  position: fixed;\n  left: calc(50% - 100px);\n  right: calc(50% - 100px);\n  z-index: 5;\n  background: yellow;\n  font-size: 14px;\n  border-radius: 0 0 10px 10px;\n  text-align: center;\n  font-weight: bold;\n  width: auto !important;\n  height: 0;\n  line-height: 30px;\n  overflow: hidden;\n  transition: height .5s cubic-bezier(0.4, 0.0, 0.2, 1);\n\n  z-index: 9999;\n\n  &.currentlyOffline {\n    height: 30px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offlineBanner": `styles__offlineBanner___SG1Mz`,
	"currentlyOffline": `styles__currentlyOffline___s35jV`
};
export default ___CSS_LOADER_EXPORT___;
