// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__agreeTermsLabel___iet6M{color:#fff!important;text-align:center}.styles__footerLink___SUIup{border-bottom:1px solid #a4e4d3;color:#a4e4d3;text-decoration:none;transition:border-width .1s ease-in-out;white-space:nowrap}.styles__footerLink___SUIup:hover{border-bottom-width:3px}a.styles__link___x7pro{border-bottom:1px solid #4611a9;color:#4611a9;cursor:pointer;font-size:.75em;text-decoration:none;transition:border-width .1s ease-in-out;width:fit-content}a.styles__link___x7pro:hover{border-bottom-width:3px}`, "",{"version":3,"sources":["webpack://./src/components/ChangePasswordCard/styles.scss","webpack://./../../styles/colors.json"],"names":[],"mappings":"AAEA,iCAEE,oBAAA,CADA,iBACA,CAGF,4BACE,+BAAA,CACA,aCRe,CDSf,oBAAA,CACA,uCAAA,CACA,kBAAA,CAEA,kCACE,uBAAA,CAIJ,uBAEE,+BAAA,CACA,aCXW,CDSX,cAAA,CAGA,eAAA,CACA,oBAAA,CACA,uCAAA,CACA,iBAAA,CACA,6BACE,uBAAA","sourcesContent":["@import \"../../styles/colors.json\";\n\n.agreeTermsLabel {\n  text-align: center;\n  color: #ffffff!important;\n}\n\n.footerLink {\n  border-bottom: 1px solid $lightHighlight;\n  color: $lightHighlight;\n  text-decoration: none;\n  transition: border-width .1s ease-in-out;\n  white-space: nowrap;\n\n  &:hover {\n    border-bottom-width: 3px;\n  }\n}\n\na.link {\n  cursor: pointer;\n  border-bottom: 1px solid $muiPrimary;\n  color: $muiPrimary;\n  font-size: .75em;\n  text-decoration: none;\n  transition: border-width .1s ease-in-out;\n  width: fit-content;\n  &:hover {\n    border-bottom-width: 3px;\n  }\n}\n","$darkBrandColor: #4611a9;\n$lightHighlight: #a4e4d3;\n$lightText: white;\n$darkYellow: #a97611;\n$emptyBackground: #f7f7f7;\n$darkText: black;\n$muiSecondary: #A10036;\n$disabledColor: rgba(0, 0, 0, 0.6);\n$lightGrey: rgb(224, 224, 224);\n$mediumGrey: rgb(188, 188, 188);\n$darkGrey: rgb(88, 88, 88);\n$muiPrimary: rgb(70, 17, 169);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreeTermsLabel": `styles__agreeTermsLabel___iet6M`,
	"footerLink": `styles__footerLink___SUIup`,
	"link": `styles__link___x7pro`
};
export default ___CSS_LOADER_EXPORT___;
