// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__customSwitchGrid___TRd8f{flex-wrap:nowrap!important;margin-left:.5em;width:auto!important}`, "",{"version":3,"sources":["webpack://./src/components/QuestionSwitch/styles.scss"],"names":[],"mappings":"AAAA,kCAEE,0BAAA,CACA,gBAAA,CAFA,oBAEA","sourcesContent":[".customSwitchGrid{\n  width: auto!important;\n  flex-wrap: nowrap!important;\n  margin-left: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customSwitchGrid": `styles__customSwitchGrid___TRd8f`
};
export default ___CSS_LOADER_EXPORT___;
