// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__selectedEmojiHolder___dd8pr{align-items:center;display:flex;justify-content:space-evenly;margin-top:1em}`, "",{"version":3,"sources":["webpack://./src/components/EmojiPicker/styles.scss"],"names":[],"mappings":"AAAA,qCAEE,kBAAA,CADA,YAAA,CAEA,4BAAA,CACA,cAAA","sourcesContent":[".selectedEmojiHolder{\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  margin-top: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedEmojiHolder": `styles__selectedEmojiHolder___dd8pr`
};
export default ___CSS_LOADER_EXPORT___;
