const { connect } = require('react-redux');
const { context } = require('app-context');
const EditGroupPage = require('../components/EditGroupPage');

const actions = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state, { match: { params } }) => ({
        groupDetails: selectors.getGroup_forGroupDetail(state, params.id),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getFilteredSchoolRoles(state),
        globalInputPageHasError: selectors.getInputPageHasErrors(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state)
    }),
    {
        onSubmit: actions.classes.update,
        setGlobalInputPageError: actions.app.setGlobalInputPageError,
        removeGlobalInputPageError: actions.app.removeGlobalInputPageError
    }
)(EditGroupPage);
