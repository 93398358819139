// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__suggestionsContainer___De3nt{padding:0;position:absolute;width:100%;z-index:3}.styles__suggestionsList___hW8Sc{border:1px solid rgba(0,0,0,.12);border-radius:10px;list-style-type:none!important;margin:0!important;max-height:200px;overflow-y:scroll;padding:0!important;z-index:100}.styles__suggestion___i_8LM{line-height:1.5;padding:5px 16px;transition:all .45s cubic-bezier(.23,1,.32,1) 0ms}.styles__suggestion___i_8LM:hover{cursor:pointer}.styles__suggestion___i_8LM>div{white-space:pre-wrap;width:auto!important}`, "",{"version":3,"sources":["webpack://./src/components/material-react-autocomplete/styles.scss"],"names":[],"mappings":"AAAA,sCACE,SAAA,CAEA,iBAAA,CACA,UAAA,CAFA,SAEA,CAGF,iCAII,gCAAA,CACA,kBAAA,CAJA,8BAAA,CAEA,kBAAA,CAGA,gBAAA,CACA,iBAAA,CALA,mBAAA,CAMA,WAAA,CAGJ,4BACE,eAAA,CAEA,gBAAA,CADA,iDACA,CACA,kCACE,cAAA,CAIF,gCACE,oBAAA,CACA,oBAAA","sourcesContent":[".suggestionsContainer  {\n  padding: 0;\n  z-index: 3;\n  position: absolute;\n  width: 100%;\n}\n\n.suggestionsList {\n    list-style-type: none !important;\n    padding: 0 !important;\n    margin: 0 !important;\n    border: 1px solid rgba(0, 0, 0, 0.12);\n    border-radius: 10px;\n    max-height: 200px;\n    overflow-y: scroll;\n    z-index: 100;\n}\n\n.suggestion {\n  line-height: 1.5;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n  padding: 5px 16px;\n  &:hover {\n    cursor: pointer;\n    //background-color: rgb(224, 224, 224);\n  }\n\n  &>div {\n    white-space: pre-wrap;\n    width: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suggestionsContainer": `styles__suggestionsContainer___De3nt`,
	"suggestionsList": `styles__suggestionsList___hW8Sc`,
	"suggestion": `styles__suggestion___i_8LM`
};
export default ___CSS_LOADER_EXPORT___;
