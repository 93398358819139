// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__snackbar___GO0R4>div>div{align-items:center;display:flex;text-align:center}.styles__snackbar___GO0R4 button{margin:-6px -16px -6px 24px!important}`, "",{"version":3,"sources":["webpack://./src/components/Snackbar/styles.scss"],"names":[],"mappings":"AAEI,kCAEI,kBAAA,CADA,YAAA,CAEA,iBAAA,CAGJ,iCACI,qCAAA","sourcesContent":[".snackbar {\n\n    & > div > div {\n        display: flex;\n        align-items: center;\n        text-align: center;\n    }\n\n    button {\n        margin: -6px -16px -6px 24px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `styles__snackbar___GO0R4`
};
export default ___CSS_LOADER_EXPORT___;
