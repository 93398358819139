// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__bugReportWrapper___wq1cY{width:100%}.styles__bugReportInnerWrapper___gjRAS{display:flex;flex-flow:column nowrap;padding:1.5em;width:100%}.styles__bugReportInnerWrapper___gjRAS>div{width:100%!important}.styles__bugReportInnerWrapper___gjRAS h2{margin-top:0}.styles__formControl___RbLRL{margin:.5em 0!important}.styles__fullWidth___szsvH{width:100%}.styles__appVersionWrapper___OkusI{font-size:.875rem;opacity:.75;padding-bottom:30px;text-align:center;width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/app/bugReport/components/BugReportPage/styles.scss"],"names":[],"mappings":"AAEA,kCACE,UAAA,CAGF,uCAGE,YAAA,CACA,uBAAA,CAFA,aAAA,CADA,UAGA,CAEA,2CACE,oBAAA,CAGF,0CACE,YAAA,CAIJ,6BACE,uBAAA,CAEF,2BACE,UAAA,CAEF,mCAGE,iBAAA,CACA,WAAA,CACA,mBAAA,CAHA,iBAAA,CADA,UAIA","sourcesContent":["@import \"../../../../../styles/colors.json\";\n\n.bugReportWrapper {\n  width: 100%;\n}\n\n.bugReportInnerWrapper {\n  width: 100%;\n  padding: 1.5em;\n  display: flex;\n  flex-flow: column nowrap;\n\n  & > div {\n    width: 100% !important;\n  }\n\n  h2 {\n    margin-top: 0;\n  }\n}\n\n.formControl{\n  margin: 0.5em 0em!important;\n}\n.fullWidth {\n  width: 100%;\n}\n.appVersionWrapper {\n  width: 100%;\n  text-align: center;\n  font-size: 0.875rem;\n  opacity: .75;\n  padding-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bugReportWrapper": `styles__bugReportWrapper___wq1cY`,
	"bugReportInnerWrapper": `styles__bugReportInnerWrapper___gjRAS`,
	"formControl": `styles__formControl___RbLRL`,
	"fullWidth": `styles__fullWidth___szsvH`,
	"appVersionWrapper": `styles__appVersionWrapper___OkusI`
};
export default ___CSS_LOADER_EXPORT___;
