const React = require('react');

const { default: AccountIcon } = require('@mui/icons-material/AccountBox');
const { default: AccountIconOutlined } = require('@mui/icons-material/AccountBoxOutlined');

const { default: Classes } = require('./styles.scss');
const T = require('prop-types');

module.exports = function NoUserProfilePic(props) {

    const {
        outlined,
        insideAvatar,
        iconColor,
        ...rest
    } = props;

    const iconProps = {
        style: {
            padding: insideAvatar ? '0.25rem' : '0',
            color: iconColor,
            ...rest.style
        },
        className: Classes.emptyPicIcon,
        ...rest
    };

    return (
        outlined
            ? <AccountIconOutlined {...iconProps} />
            : <AccountIcon {...iconProps} />
    );
};

module.exports.propTypes = {
    outlined: T.bool,
    insideAvatar: T.bool,
    iconColor: T.string
};
