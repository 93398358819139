// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__classListItem___SbPwK{padding:0!important}.styles__detailItem___WWuwG{padding-top:0!important;pointer-events:none}.styles__detailText___AD9y4{font-size:14px;line-height:14px;width:75%}.styles__unreadMessagesBadge___WW28c{bottom:5px;position:absolute!important;right:20px}.styles__secondaryTextHolder___zaHbf{font-size:15px;height:auto!important;white-space:normal!important;width:100%}.styles__primaryText___qzHdq{color:#000}.styles__titleAnnouncement___kwpik{display:inline-block;font-style:italic;margin:0 .5rem;opacity:.5}`, "",{"version":3,"sources":["webpack://./src/routes/app/classes/components/ClassListItem/styles.scss"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAGF,4BACE,uBAAA,CACA,mBAAA,CAGF,4BACE,cAAA,CACA,gBAAA,CACA,SAAA,CAGF,qCAEE,UAAA,CADA,2BAAA,CAEA,UAAA,CAGF,qCAIE,cAAA,CAHA,qBAAA,CAEA,4BAAA,CADA,UAEA,CAGF,6BACE,UAAA,CAGF,mCACE,oBAAA,CAGA,iBAAA,CAFA,cAAA,CACA,UACA","sourcesContent":[".classListItem {\n  padding: 0 !important;\n}\n\n.detailItem {\n  padding-top: 0 !important;\n  pointer-events: none;\n}\n\n.detailText {\n  font-size: 14px;\n  line-height: 14px;\n  width: 75%;\n}\n\n.unreadMessagesBadge {\n  position: absolute !important;\n  bottom: 5px;\n  right: 20px;\n}\n\n.secondaryTextHolder {\n  height: auto !important;\n  width: 100%;\n  white-space: normal !important;\n  font-size: 15px;\n}\n\n.primaryText{\n  color: #000000;\n}\n\n.titleAnnouncement {\n  display: inline-block;\n  margin: 0 0.5rem;\n  opacity: 0.5;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classListItem": `styles__classListItem___SbPwK`,
	"detailItem": `styles__detailItem___WWuwG`,
	"detailText": `styles__detailText___AD9y4`,
	"unreadMessagesBadge": `styles__unreadMessagesBadge___WW28c`,
	"secondaryTextHolder": `styles__secondaryTextHolder___zaHbf`,
	"primaryText": `styles__primaryText___qzHdq`,
	"titleAnnouncement": `styles__titleAnnouncement___kwpik`
};
export default ___CSS_LOADER_EXPORT___;
